:root {
  font-size: 14px;
}

.MuiButton-root:focus {
  outline: none !important;
}

.MuiIconButton-root:focus {
  outline: none !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;